import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export const KPIBox = ({ isLoading, isActive, handleOnClick, title, tooltip, number, colorClass, iconClass, ...props }) => {
  return (
    <li
      onClick={handleOnClick}
      className={classnames("summary-box", { loading: isLoading }, colorClass, {"active": isActive})}
    >
      <div className="summary-box-icon-wrapper">
        <span className={classnames(iconClass, "icon" )} />
      </div>
      <div className="summary-box-number-wrapper">
        <div className="summary-box-number">{number}</div>
      </div>
      <div className="summary-box-band">
        {title}
        <a href="#" title={tooltip}>
          <span className="summary-box-tooltip-icon shape-circle fa fa-info-circle" />
        </a>
      </div>
      <div className="triangle-area">
        <div className="triangle" />
        <div className="triangle-overlay" />
      </div>
    </li>
  );
};
