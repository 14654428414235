import * as actionTypes from "./actionTypes";

export const setIsLoading = isLoading => {
  return {
    type: actionTypes.SET_IS_LOADING,
    value: isLoading
  };
};

export const setIsLoaded = isLoaded => {
  return {
    type: actionTypes.SET_IS_LOADED,
    value: isLoaded
  };
};

export const setReleaseNotesData = data => {
  return {
    type: actionTypes.SET_RELEASE_NOTES_DATA,
    value: data
  };
};

// Sets the index of the  release note to display
export const setDisplayReleaseNoteIndex = index => {
  return {
    type: actionTypes.SET_DISPLAY_RELEASE_NOTE_INDEX,
    value: index
  };
};
