/**
 * Account Loading Component
 */

import React from "react";
import "../styles/User.scss";
import "../styles/Loading.scss";

class Loading extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="center widget widget-xl">
                <div className="section">
                    <div className="loading-state-container">
                        <svg
                            className="loading-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 340 340"
                        >
                            <circle cx="170" cy="170" r="160" />
                            <circle cx="170" cy="170" r="135" />
                            <circle cx="170" cy="170" r="110" />
                            <circle cx="170" cy="170" r="85" />
                        </svg>
                        <h3 className="loading-title">Loading</h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loading;
