/**
 * Account Reset Password Component
 */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OrienAvatarLogo from "../../assets/Orien_logo_4C_2ndLOGOS_ORIENAVATAR.svg";
import { User } from "../../../core";
import { LogoImageWrapper, ErrorMessageWrapper, FieldWrapper, FieldErrorMessageWrapper, Button, Spinner, FooterLinksWrapper, Title, P } from "../styled/nonAuthenticatedAccountStyles";
import { STATE_KEY as USER_STATE_KEY } from "../../User";

import OrienAvatarAPI, { GENERIC_OA_API_KEY } from "../../api";
import { useAnalytics } from "../../../hooks";

// Using Yup to provide the schema validation for the form.
const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email("Enter a valid email address")
});

export const ResetPassword = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordResetRequested, setPasswordResetRequested] = useState(false);
  const { trackEvent } = useAnalytics();
  const user = useSelector(state => state[USER_STATE_KEY]);
  const isUserAuthenticated = user.userName ? true : false;

  const handleSubmit = (values, actions) => {
    setHasError(false);
    setErrorMessage("");
    setPasswordResetRequested(false);

    OrienAvatarAPI.request({
      method: "post",
      url: `https://${__API__}/user/password/forgot`,
      data: {
        email: values.email
      },
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": GENERIC_OA_API_KEY
      }
    })
      .then(response => {
        trackEvent("Users", "Reset password succeeded", values.email);

        actions.setSubmitting(false);
        setHasError(false);
        setPasswordResetRequested(true);
      })
      .catch(error => {
        trackEvent("Users", "Reset password failed", values.email);

        const errorJSON = JSON.parse(JSON.stringify(error));
        let response = "An error has occurred. Please try again.";
        if (errorJSON && errorJSON.response && errorJSON.response.data) {
          response = errorJSON.response.data;
        }

        actions.setSubmitting(false);

        setHasError(true);
        setErrorMessage(response);
        setPasswordResetRequested(false);
      });
  };

  return (
    <>
      <LogoImageWrapper role="heading" aria-level="1">
        <img src={OrienAvatarLogo} alt="ORIEN Avatar Logo" />
      </LogoImageWrapper>
      {hasError && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}

      <Title center>Password Reset</Title>
      {!passwordResetRequested ? (
        <P center>Please enter your email address to request a new password.</P>
      ) : (
        <P center>
          Your password has been reset.
          <br /> Please check your email for the new password.
        </P>
      )}

      {!passwordResetRequested && (
        <Formik
          initialValues={{
            email: ""
          }}
          validationSchema={resetPasswordSchema}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FieldWrapper>
                <Field type="text" name="email" id="email" placeholder="Email Address" autoComplete="email" onBlur={null} autoFocus aria-label="Email Address" />
                <FieldErrorMessageWrapper name="email" component="div" className="error" />
              </FieldWrapper>

              <FieldWrapper>
                <Button type="submit" disabled={isSubmitting}>
                  <span>Reset Password</span>
                  {isSubmitting && <Spinner />}
                </Button>
              </FieldWrapper>
            </Form>
          )}
        </Formik>
      )}

      <FooterLinksWrapper>
        {isUserAuthenticated ? <Link to="/">Home</Link> : <Link to="/account/login">Login</Link>}
        <Link to="/account/requestaccount">Request an account</Link>
        {/*
         *<Link to="/faq">FAQ</Link>
         */}
      </FooterLinksWrapper>
    </>
  );
};
