import * as actionTypes from "./actionTypes";

export const setIsLoading = isLoading => {
  return {
    type: actionTypes.SET_IS_LOADING,
    value: isLoading
  };
};

export const setIsLoaded = isLoaded => {
  return {
    type: actionTypes.SET_IS_LOADED,
    value: isLoaded
  };
};

export const setUserManualsData = data => {
  return {
    type: actionTypes.SET_USER_MANUALS_DATA,
    value: data
  };
};
