import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { STATE_KEY as RELEASE_NOTES_STATE_KEY, APPLICATION } from "../../ReleaseNotes";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import ContentLoader from "react-content-loader";
import OrienAvatarAPI from "../../api";
import Markdown from "react-remarkable";
import moment from "moment";
import styled from "styled-components";

export const ReleaseNote = () => {
  const { releaseNoteId } = useParams();
  const { releaseNotes } = useSelector(state => state[RELEASE_NOTES_STATE_KEY]);
  const { apiKey } = useSelector(state => state[USER_STATE_KEY]);
  const [releaseNote, setReleaseNote] = useState(null);

  useEffect(() => {
    const foundReleaseNote = releaseNotes.find(note => note.releasenotesID == releaseNoteId);

    if (!foundReleaseNote) {
      return;
    }

    OrienAvatarAPI.request({
      method: "get",
      url: `https://${__API__}/releasenotes/${APPLICATION}/${releaseNoteId}`,
      headers: {
        "X-Api-Key": apiKey
      }
    })
      .then(({ data }) => {
        setReleaseNote(data);
      })
      .catch(error => {
        console.log(`Failed to fetch release note id: ${releaseNoteId}`, error);
      })
      .finally(() => {});
  }, [releaseNotes, releaseNoteId]);

  return (
    <Wrapper>
      {!releaseNote ? (
        <SkeletonContentText />
      ) : (
        <>
          <ReleaseDateWrapper>Released: {moment(releaseNote.releasedate).format("MMMM Do, YYYY")}</ReleaseDateWrapper>
          <Markdown source={releaseNote.notes} container="div" />
        </>
      )}
    </Wrapper>
  );
};

const SkeletonContentText = () => {
  return (
    <ContentLoader viewBox="0 0 700 220">
      <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="99" rx="5" ry="5" width="400" height="100" />
    </ContentLoader>
  );
};

const Wrapper = styled.section`
  * {
    line-height: 1.7;
  }

  a {
    color: #0054b9;
  }

  h1 {
    margin-top: 1rem;
    text-align: center;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.3em;
    text-align: left;
  }

  p {
    margin: 0.5rem 0 1.5rem 0;

    & + h2,
    h3,
    h4,
    h5 {
      margin-top: 1.8rem;
    }
  }

  ul,
  ol {
    margin-left: 2rem;

    li ul {
      margin-left: 2rem;
    }
  }

  hr {
    margin-top: 3.5rem;
  }
`;

const ReleaseDateWrapper = styled.div`
  text-align: right;
  color: #007cbb;
  font-weight: 500;
`;
