import * as actionTypes from "./actionTypes";

const defaultState = {
  isLoading: false,
  isLoaded: false,
  application: "orienavatar",
  currentReleaseNoteIndex: null,
  displayReleaseNoteIndex: null,
  releaseNotes: []
};

export const reducer = (state = defaultState, action) => {
  let newState = {
    ...state
  };
  switch (action.type) {
    case actionTypes.SET_IS_LOADING: {
      newState.isLoading = action.value;
      return newState;
    }
    case actionTypes.SET_IS_LOADED: {
      newState.isLoaded = action.value;
      return newState;
    }
    case actionTypes.SET_RELEASE_NOTES_DATA: {
      newState.releaseNotes = action.value;
      return newState;
    }
    default:
      return state;
  }
};
