import React, { useContext, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Manual, STATE_KEY as USER_MANUALS_STATE_KEY, CBIOPORTAL_TYPE, DNANEXUS_TYPE, IMV2_TYPE, IMV4_TYPE, ORIENAVATAR_TYPE, SFTP_TYPE } from "../../UserManuals";
import * as actions from "../actions";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import { AbilityContext, subjects } from "../../../ability";
import ContentLoader from "react-content-loader";
import OrienAvatarAPI from "../../api";
import styled from "styled-components";

export const UserManuals = memo(function UserManuals_Memo() {
  const ability = useContext(AbilityContext);
  const { isLoading, isLoaded, userManuals } = useSelector((state) => state[USER_MANUALS_STATE_KEY]);
  const user = useSelector((state) => state[USER_STATE_KEY]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !isLoaded) {
      dispatch(actions.setIsLoading(true));

      OrienAvatarAPI.request({
        method: "get",
        url: `https://${__GENERICAPI__}/manual/${user.userType}/${user.company}`,
        headers: {
          Authorization: user.apiKey,
          "Content-Type": "application/json",
        },
      })
        .then(({ data }) => {
          dispatch(actions.setUserManualsData(data));
          dispatch(actions.setIsLoaded(true));
        })
        .catch((error) => {
          console.log("Failed to fetch user manuals", error);
        })
        .finally(() => {
          dispatch(actions.setIsLoading(false));
        });
    }
  }, []);

  const exists = (key) => !!userManuals[key];
  const getTitle = (key) => userManuals[key].Title;
  const getURL = (key) => userManuals[key].URL;
  const getVersion = (key) => userManuals[key].Version;
  const getReleaseDate = (key) => userManuals[key].ReleaseDate;

  const renderManual = (key) => <Manual title={getTitle(key)} url={getURL(key)} version={getVersion(key)} releaseDate={getReleaseDate(key)} />;

  return (
    <>
      {!isLoaded ? (
        <SkeletonContentManuals />
      ) : (
        <>
          <h2>User Manuals</h2>
          <Wrapper>
            {exists(ORIENAVATAR_TYPE) && renderManual(ORIENAVATAR_TYPE)}
            {ability.can("view", subjects.APP_IMV2) && exists(IMV2_TYPE) && renderManual(IMV2_TYPE)}
            {ability.can("view", subjects.APP_IMV4) && exists(IMV4_TYPE) && renderManual(IMV4_TYPE)}
            {exists(SFTP_TYPE) && renderManual(SFTP_TYPE)}
            {exists(DNANEXUS_TYPE) && renderManual(DNANEXUS_TYPE)}
            {ability.can("view", subjects.APP_CBIOPORTAL) && exists(CBIOPORTAL_TYPE) && renderManual(CBIOPORTAL_TYPE)}
          </Wrapper>
        </>
      )}
    </>
  );
});

const SkeletonContentManuals = () => {
  return (
    <ContentLoader viewBox="0 -20 800 140">
      <rect x="0" y="0" rx="5" ry="5" width="200" height="100" />
      <rect x="210" y="0" rx="5" ry="5" width="200" height="100" />
      <rect x="420" y="0" rx="5" ry="5" width="200" height="100" />
      <rect x="630" y="0" rx="5" ry="5" width="200" height="100" />
    </ContentLoader>
  );
};

const Wrapper = styled.div`
  display: grid;
  margin: 3rem 0;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-row-gap: 40px;
  grid-column-gap: 40px;
  justify-content: center;
  align-items: center;
`;
