import React, { memo } from "react";
import styled from "styled-components";

export const Manual = memo(function Manual_Memo({ title, url, version, releaseDate }) {
  return (
    <ManualLinkWrapper href={url} target="_blank" title={`Click to access ${title}`}>
      <IconWrapper>
        <i className="far fa-file-pdf"></i>
      </IconWrapper>
      <TitleWrapper>{title}</TitleWrapper>
      <VersionWrapper>Version: {version}</VersionWrapper>
      <ReleaseDateWrapper>Release Date: {releaseDate}</ReleaseDateWrapper>
    </ManualLinkWrapper>
  );
});

const ManualLinkWrapper = styled.a`
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 1rem;
  text-decoration: none;
  color: #000000;
`;

const IconWrapper = styled.div`
  height: 40px;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 4rem;
  color: #ff0000;
`;

const TitleWrapper = styled.div`
  padding: 1rem;
  font-size: 1.6rem;
  text-align: center;
`;

const VersionWrapper = styled.div`
  font-size: 1.1rem;
  text-align: center;
`;

const ReleaseDateWrapper = styled.div`
  font-size: 1.1rem;
  text-align: center;
`;
