import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { Login, ResetPassword, RequestAccount } from "../../User";

export const NonAuthenticatedAccount = () => {
  const { path } = useRouteMatch();
  //console.log('path', path);

  return (
    <Wrapper>
      <Container>
        <Switch>
          <Route path={`${path}/login`} component={Login} />
          <Route path={`${path}/resetpassword`} component={ResetPassword} />
          <Route path={`${path}/requestaccount`} component={RequestAccount} />
          <Redirect exact from={path} to={`${path}/login`} />
        </Switch>
      </Container>
      <FooterWrapper>
        For support please contact <MailToLink href="mailto:support@m2gen.com">support@m2gen.com</MailToLink>
      </FooterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;

  a {
    color: #0056b3;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Container = styled.main`
  border: 1px solid #ededed;
  padding: 2rem;
  margin: 1rem;
  border-radius: 8px;
  max-width: 550px;
  width: 100%;
  background-color: #fff;
  margin-top: 3rem;
`;

const FooterWrapper = styled.footer`
  margin-top: 1rem;
  color: #555555;
`;

const MailToLink = styled.a`
  color: #555555 !important;
`;

const Ribbon = styled.div`
  width: 8rem;
  height: 6rem;
  color: #ffffff;
  margin: 1rem 0.5rem 0;
  padding-top: 1rem;
  position: absolute;
  top: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: 1.8rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  display: block;
  background: linear-gradient(to bottom, #cc2920 0%, #d24b44 100%);
  -webkit-filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 4rem solid transparent;
    border-left: 4rem solid transparent;
    border-top: 1.5rem solid #d24b44;
    position: absolute;
    top: 7rem;
    left: 0;
  }
`;
