import React from "react";
import { useAutoLogoutTimer } from "../../../hooks";


export const AutoLogoutTimer = () => { 
    const { secondsLeftToTimeout, timeoutFired, warningTimeoutFired } = useAutoLogoutTimer( 30 * 60, 90);

    return(
    null
    );
};
