/**
 * CBioPortal Terms Component
 */

import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import OrienAvatarAPI from "../../api";
import { STATE_KEY as USER_STATE_KEY } from "../";
import styled from "styled-components";

import "../styles/CBioPortalTerms.scss";

const SectionContainer = styled.div`
  margin: 1.3rem;
`;

export const CBioPortalTerms = memo(() => {
  const [cBioPortalSAML, setCBioPortalSAML] = useState(null);
  const user = useSelector(state => state[USER_STATE_KEY]);

  const fetchCBioPortalSAML = () => {
    const { apiKey, commonAuthId } = user;

    OrienAvatarAPI.request({
      method: "post",
      url: `https://${__API__}/user/auth`,
      headers: {
        "X-Api-Key": apiKey
      },
      data: {
        commonAuthId: commonAuthId,
        serviceprovider: "cbio"
      }
    })
      .then(response => {
        if (response && response.data) {
          setCBioPortalSAML(response.data);
        }
      })
      .catch(error => {
        console.log("Failed to fetch cBioPortal SAML", error);
      });
  };

  useEffect(() => {
    const { hascbioPortal } = user;

    if (hascbioPortal) {
      fetchCBioPortalSAML();
    }
  }, []);

  // the terms can be found here:
  // https://cbioportal.orienavatar.com/terms/cbioportal.html
  return (
    <>
      {cBioPortalSAML && (
        <form method="post" action="https://cbioportal.orienavatar.com/oriencbioportal/saml/SSO" name="cbioPortalForm" target="_blank">
          <input type="hidden" name="SAMLResponse" value={cBioPortalSAML} />
          <div className="cbioportal-terms-container">
            <div className="section-container">
              <h1>cBioPortal</h1>
            </div>
            <SectionContainer>
              <h2>ORIEN TIER I DATA SHARING ACCESS TERMS &amp; CONDITIONS</h2>
            </SectionContainer>
            <SectionContainer>
              <p>Access to other Member’s ORIEN Avatar data through the molecular informatics tool(s) enabled by M2Gen is available to authorized representatives at ORIEN Member institutions that have “opted in” to Tier I data sharing by signing the Data Sharing Addendum to the ORIEN Master Collaboration Agreement.</p>
              <p>Tier I data sharing via this molecular tool is for the purpose of promoting collaboration by allowing Members to have view-only access to the integrated clinical and molecular data created through participation in the ORIEN Avatar Research Program.</p>
              <p>By accessing the data through this tool, the ORIEN Member representative agrees to the following terms and conditions:</p>
            </SectionContainer>
            <SectionContainer>
              <ul>
                <li>User may not use the data accessed for a grant application or publication without express written consent of disclosing members of such data.</li>
                <li>All data access, sharing and use must be consistent with the applicable Total Cancer Care Protocol and the applicable patient informed consent and author.</li>
                <li>Only de-identified data, as defined by HIPAA, will be made available for view by Tier I users at ORIEN participating sites.</li>
                <li>Data may not be downloaded or retained in your systems.</li>
                <li>Should an ORIEN Member terminate its participation in the Avatar Data Sharing Program, data will no longer be available for viewing.</li>
              </ul>
            </SectionContainer>
            <SectionContainer>
              <h3>I agree to the above terms and conditions by clicking here.</h3>
            </SectionContainer>
            <SectionContainer>
              <button className="section-button" type="submit">
                Accept
              </button>
            </SectionContainer>
          </div>
        </form>
      )}
    </>
  );
});
