/**
 * User & Authentication Actions
 */
import * as actionTypes from "./actionTypes";
import * as constants from "./constants";
import OrienAvatarAPI, { GENERIC_OA_API_KEY } from "../api";
import User from "./";
import { useSession } from "../../hooks";

export function requestSession() {
    return dispatch => {
        let currentSession = sessionStorage.getItem(
            constants.SESSION_STORAGE_KEY
        );
        if (currentSession) {
            dispatch({
                type: actionTypes.USER_AUTHENTICATED,
                value: JSON.parse(currentSession)
            });
        } else {
            dispatch({
                type: actionTypes.USER_NOT_AUTHENTICATED,
                value: null
            });
        }
    };
}

export const killSession = () => {
  const { endSession } = useSession();

  endSession();
}


export const setRequiredSignature = (required) => {
  return {
    type: actionTypes.SET_REQUIRED_SIGNATURE,
    value: required
  };
};
