import * as actionTypes from "./actionTypes";

const defaultState = {
  isLoading: false,
  isLoaded: false,
  userManuals: []
};


export const reducer = (state = defaultState, action) => {
  let newState = {
    ...state
  };

  switch(action.type) {
    case actionTypes.SET_IS_LOADING: {
      newState.isLoading = action.value;
      return newState;
    }
    case actionTypes.SET_IS_LOADED: {
      newState.isLoaded = action.value;
      return newState;
    }
    case actionTypes.SET_USER_MANUALS_DATA: {
      newState.userManuals = action.value;
      return newState;
    }
    default: {
      return state;
    }
  }
};
