import * as actionTypes from "./actionTypes";

const defaultState = {
  isLoading: false,
  isLoaded: false,
  data: {
    dashboard: {
      ID: "Dashboard",
      summaryBoxColorClass: "summary-box-blue",
      summaryBoxIconClass: "far fa-star",
      summaryBoxTitle: "Dashboard",
      summaryBoxTooltip: "The dashboard displays Key Performance Indicators for the Avatar program.",
      summaryBoxLink: "/",
      summaryBoxIsLoaded: true
    },
    molecular: {
      ID: "Molecular",
      summaryBoxColorClass: "summary-box-light-blue",
      summaryBoxIconClass: "fa m2gen-icon-molecule",
      summaryBoxNumber: "",
      summaryBoxTitle: "Molecular Data Queue",
      summaryBoxTooltip: "The number of patients that have at least one specimen in the Molecular progression pipeline that will potentially be included in an Avatar Release.",
      summaryBoxLink: "/molecular",
      summaryBoxIsLoaded: false
    },
    clinical: {
      ID: "Clinical",
      summaryBoxColorClass: "summary-box-purple",
      summaryBoxIconClass: "far fa-address-card",
      summaryBoxNumber: "",
      summaryBoxTitle: "Clinical Data Queue",
      summaryBoxTooltip: "The number of patients that have clinical data in the Clinical progression pipeline that will potentially be included in an Avatar Release.",
      summaryBoxLink: "/clinical",
      summaryBoxIsLoaded: false
    },
    avatar: {
      ID: "Avatar",
      summaryBoxColorClass: "summary-box-teal",
      summaryBoxIconClass: "fa fa-stethoscope",
      summaryBoxNumber: "",
      summaryBoxTitle: "Avatar Patients",
      summaryBoxTooltip: "The number of unique patients that have been released in Avatar.",
      summaryBoxLink: "/avatar",
      summaryBoxIsLoaded: false
    }
  },
  counts: {
    molecularCounts: "",
    clinicalCounts: "",
    siteAvatarCounts: "",
    networkAvatarCounts: ""
  }
};

export function reducer(state = defaultState, action) {
  let newState = {
    ...state
  };
  switch (action.type) {
    case actionTypes.SET_IS_LOADING:
      newState.isLoading = action.value;
      return newState;
    case actionTypes.SET_IS_LOADED:
      newState.isLoaded = action.value;
      return newState;
    case actionTypes.SET_SUMMARY_BOX_NUMBER:
      const { key, number } = action.value;
      newState.data[key] = {
        ...newState.data[key],
        summaryBoxNumber: number,
        summaryBoxIsLoaded: true
      };
      return newState;
    case actionTypes.SUMMARY_BOX_SELECT:
      newState.selectedSummaryBoxIndex = action.value;
      return newState;
    case actionTypes.SUMMARY_BOX_DESELECT:
      newState.selectedSummaryBoxIndex = action.value;
      return newState;
    case actionTypes.SET_COUNTS:
      newState.counts = action.value;
      return newState;
    default:
      return state;
  }
}
