/**
 * Account Change Password Component
 */

import PropTypes from "prop-types";

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import OrienAvatarAPI from "../../api";
import { useAnalytics } from "../../../hooks";

// Using Yup to provide the schema validation for the Change Password form.
const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .required("Please enter a new password")
    .min(16, "Password must be at least 16 characters")
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{16,}$/, "Password must contain lower case, upper case and numbers")
    .notOneOf([Yup.ref("oldPassword")], "New password and old password cannot be the same"),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref("newPassword")], "New passwords do not match")
    .required("Please confirm the new password")
});

export const ChangePassword = () => {
  const [companyProfilePhotoURL, setCompanyProfilePhotoURL] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const userSelector = useSelector(state => state[USER_STATE_KEY]);
  const { trackEvent } = useAnalytics();

  const handleSubmit = (values, actions) => {
    const { apiKey, userName } = userSelector;

    setHasError(false);
    setErrorMessage("");
    setPasswordChanged(false);

    // trim leading and trailing whitespace
    const username = userName.trim();
    const oldpassword = values.oldPassword.trim();
    const newpassword = values.newPassword.trim();

    OrienAvatarAPI.request({
      method: "post",
      url: `https://${__API__}/user/password/change`,
      data: {
        username,
        oldpassword,
        newpassword
      },
      headers: {
        "X-Api-Key": apiKey
      }
    })
      .then(response => {
        trackEvent("Users", "Change password succeeded", userName);

        actions.setSubmitting(false);
        setHasError(false);
        setPasswordChanged(true);
      })
      .catch(error => {
        trackEvent("Users", "Change password failed", userName);

        const message = error.response && error.response.data ? error.response.data : "An error has occurred. Please try again.";
        console.log("Failed to change password", message);

        actions.setSubmitting(false);
        setHasError(true);
        setErrorMessage(message);
        setPasswordChanged(false);
      });
  };

  return (
    <SectionWrapper>
      <SectionTitle>Change Password</SectionTitle>
      {hasError && <SectionErrorMessage>{errorMessage}</SectionErrorMessage>}

      {passwordChanged && <SectionSuccessMessage>Your password has been successfully changed.</SectionSuccessMessage>}

      {!passwordChanged && (
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            newPasswordConfirmation: ""
          }}
          validationSchema={changePasswordSchema}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <SectionFieldWrapper>
                <SectionLabel htmlFor="oldPassword">Old Password:</SectionLabel>
                <SectionField>
                  <Field type={values.showPassword ? "text" : "password"} name="oldPassword" id="oldPassword" autoComplete="current-password" onBlur={null} />
                  <ErrorMessage name="oldPassword" component={SectionFieldErrorMessage} className="error" />
                </SectionField>
              </SectionFieldWrapper>

              <SectionFieldWrapper>
                <SectionLabel htmlFor="newPassword">New Password:</SectionLabel>
                <SectionField>
                  <Field type={values.showPassword ? "text" : "password"} name="newPassword" id="newPassword" autoComplete="new-password" onBlur={null} />
                  <ErrorMessage name="newPassword" component={SectionFieldErrorMessage} className="error" />
                </SectionField>
              </SectionFieldWrapper>

              <SectionFieldWrapper>
                <SectionLabel htmlFor="newPasswordConfirmation">Confirm New Password:</SectionLabel>
                <SectionField>
                  <Field type={values.showPassword ? "text" : "password"} name="newPasswordConfirmation" id="newPasswordConfirmation" autoComplete="new-password" onBlur={null} />
                  <ErrorMessage name="newPasswordConfirmation" component={SectionFieldErrorMessage} className="error" />
                </SectionField>
              </SectionFieldWrapper>
              <SectionFieldWrapper>
                <SectionLabel />
                <SectionField>
                  <Field type="checkbox" name="showPassword" id="showPassword" /> <label htmlFor="showPassword">Show Password</label>
                </SectionField>
              </SectionFieldWrapper>

              <SectionFieldWrapper>
                <SectionLabel />
                <SectionField>
                  <SectionButtonWrapper>
                    {/*
                     *<Link to="/account">
                     *  <SectionButton>Cancel</SectionButton>
                     *  </Link>
                     */}
                    <SectionButton disabled={isSubmitting} type="submit">
                      <span>Change Password</span>
                      {isSubmitting && <Spinner />}
                    </SectionButton>
                  </SectionButtonWrapper>
                </SectionField>
              </SectionFieldWrapper>

              <SectionPasswordInfoWrapper>
                <SectionPasswordInfoTitle>Password Complexity:</SectionPasswordInfoTitle>
                <ul>
                  <li>Must be at least 16 characters</li>
                  <li>Must contain a lower case letter</li>
                  <li>Must contain an upper case letter</li>
                  <li>Must contain a numeric digit</li>
                </ul>
              </SectionPasswordInfoWrapper>
            </Form>
          )}
        </Formik>
      )}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  border: 1px solid #ededed;
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 4px;
  width: 100%;
  max-width: 650px;
`;

const SectionErrorMessage = styled.div`
  margin: 2rem 0;
  color: red;
  text-align: center;
  border: 1px dashed #ff000052;
  padding: 1rem;
  border-radius: 4px;
  background-color: #ffff001f;
`;

const SectionSuccessMessage = styled.div`
  margin: 2rem 0;
  text-align: center;
  border: 1px dashed #61616138;
  padding: 1rem;
  border-radius: 4px;
  background-color: #ffff001f;
`;

const SectionCompanyPhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionTitle = styled.h2`
  color: #007cbb;
  text-align: left;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const SectionFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.7rem 0;
`;

const SectionLabel = styled.div`
  flex: 1;
  max-width: 150px;
  font-weight: 500;
  margin-right: 2rem;
`;

const SectionField = styled.div`
  input[type="password"],
  input[type="text"] {
    width: 250px;
  }
`;

const SectionFieldErrorMessage = styled.div`
  margin-top: 0.4rem;
  font-size: 1.3rem;
  color: red;
`;

const SectionButtonWrapper = styled.div`
  & + & {
    margin-top: 2rem;
  }
`;

const SectionButton = styled.button`
  background-color: #007cbb;
  color: #ffffff;
  border: 1px solid #efefef;
  padding: 6px 8px;
  border-radius: 4px;

  &:hover {
    background-color: #0097e4;
  }
`;

const SectionButtonInfo = styled.p`
  margin-top: 0.5rem;
  color: #585858;
  font-size: 1.3rem;
`;

const SectionPasswordInfoWrapper = styled.div`
  margin-top: 3rem;

  ul {
    margin-left: 20px;

    li {
      font-size: 1.4rem;
      color: #3d484f;
      font-weight: 300;
    }
  }
`;

const SectionPasswordInfoTitle = styled.div`
  font-weight: 400;
  //font-size: 1.7rem;
  color: #3d484f;
`;

export const Spinner = styled.span`
  position: relative;
  margin: 0 1em;

  &::before {
    content: "";
    animation: 1000ms linear infinite spinner-rotate;
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 50%;
    border: dashed 3px transparent;
    border-bottom-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes spinner-rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
