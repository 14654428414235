/**
 * Account Login Component
 */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { saveState } from "../../../storage";
import { Link, Redirect } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OrienAvatarLogo from "../../assets/Orien_logo_4C_2ndLOGOS_ORIENAVATAR.svg";
import { SET_USER_SESSION, SESSION_STORAGE_KEY } from "../";
import OrienAvatarAPI from "../../api";
import { useAnalytics, useSession } from "../../../hooks";
import { LogoImageWrapper, ErrorMessageWrapper, FieldWrapper, FieldErrorMessageWrapper, Button, Spinner, FooterLinksWrapper } from "../styled/nonAuthenticatedAccountStyles";

// Using Yup to provide the schema validation for the form.
const loginSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required")
});

export const Login = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { setSessionUser } = useSession();

  const handleSubmit = (values, actions) => {
    setHasError(false);
    setErrorMessage("");

    // trim leading and trailing whitespace
    // convert to lowercase and remove everything from beginning
    // up to and including a forward or backward slash from username
    const username = values.username
      .trim()
      .toLowerCase()
      .replace(/^(.*[\\|\/])/, "");

    // then update the username field with the new parsed value.
    // true at the end causes the validation to run which shows the
    // "Required" error message under the username if needed
    actions.setFieldValue("username", username, true);

    // if the username is blank then don't submit
    if (username.length < 1) {
      actions.setSubmitting(false);
      return;
    }

    const password = values.password.trim();

    OrienAvatarAPI.request({
      method: "post",
      url: `/login`,
      data: {
        username,
        password
      },
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        setHasError(false);

        const {
          data,
          data: { userName }
        } = response;

        // Set the user account for the session
        setSessionUser(data);

        return;
      })
      .catch(error => {
        let errorMessage = "";
        let analyticsAction = "";

        // If 401
        if (error.response && error.response.status && error.response.status == 401) {
          // If the user is currently locked out
          if (error.response.data && error.response.data.isLocked) {
            analyticsAction = "Login failed because the account is locked";
            errorMessage = "Your account is currently locked. Please contact support@m2gen.com for assistance.";
            // If the user's password has expired
          } else if (error.response.data && error.response.data.passwordExpired) {
            analyticsAction = "Login failed because the account's password has expired";
            errorMessage = 'Your password has expired. Please click the "Forgot password" link below to reset.';
          } else {
            // otherwise, the username or password was invalid
            analyticsAction = "Login failed";
            errorMessage = "Login information is invalid. Please try again.";
          }
        } else {
          // an unknown error occurred
          const statusCode = error.response && error.response.status ? error.response.status : "?";
          analyticsAction = `Login failed with status code ${statusCode}`;
          errorMessage = "An error occurred. Please try again.";
        }

        //Analytics.setUserId(username);
        trackEvent("Users", analyticsAction, username);
        console.log("Failed to login", errorMessage);

        actions.setSubmitting(false);
        setHasError(true);
        setErrorMessage(errorMessage);

        return;
      });
  };

  return (
    <>
      <LogoImageWrapper role="heading" aria-level="1">
        <img src={OrienAvatarLogo} alt="ORIEN Avatar Logo" />
      </LogoImageWrapper>
      {hasError && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}

      <Formik
        initialValues={{
          username: "",
          password: ""
        }}
        validationSchema={loginSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FieldWrapper>
              <Field type="text" name="username" id="username" placeholder="Username" autoComplete="username" onBlur={null} autoFocus aria-label="Username" />
              <FieldErrorMessageWrapper name="username" component="div" />
            </FieldWrapper>

            <FieldWrapper>
              <Field type="password" name="password" id="password" placeholder="Password" autoComplete="current-password" onBlur={null} aria-label="Password" />
              <FieldErrorMessageWrapper name="password" component="div" />
            </FieldWrapper>

            <FieldWrapper>
              <Button type="submit" disabled={isSubmitting}>
                <span>Login</span>
                {isSubmitting && <Spinner />}
              </Button>
            </FieldWrapper>

            <FooterLinksWrapper>
              <Link to="/account/resetpassword">Forgot password?</Link>
              <Link to="/account/requestaccount">Request an account</Link>
              {/*
               *<Link to="/faq">
               *    FAQ
               *</Link>
               */}
            </FooterLinksWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};
