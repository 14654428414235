/**
 * User Reducer
 */

import * as actionTypes from "./actionTypes";
const defaultState = {
  userName: null,
  fullName: "",
  company: "",
  title: "",
  ProfileID: "",
  requiredSignature: true,
  unreadMessages: null,
  DnaNexusLoginUrl: "https://platform.dnanexus.com/login?partner=m2gen"
};

export const reducer = (state = defaultState, action) => {
  let newState = {
    ...state
  };
  switch (action.type) {
    case actionTypes.USER_AUTHENTICATED: {
      newState = action.value;
      return newState;
    }
    case actionTypes.USER_NOT_AUTHENTICATED: {
      return newState;
    }
    case actionTypes.SET_REQUIRED_SIGNATURE: {
      newState.requiredSignature = action.value;
      return newState;
    }
    case actionTypes.SET_USER_SESSION: {
      newState = action.value;
      return newState;
    }
    case actionTypes.UNSET_USER_SESSION: {
      newState = {
        ...defaultState
      };
      return newState;
    }
    default:
      return state;
  }
}
