import React from "react";
import PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";

export const ReleaseNotesList = ({ releaseNotes = [] }) => {
  const { url } = useRouteMatch();

  return (
    <TableWrapper>
      <table>
        <thead>
          <tr>
            <th>Name / Version</th>
            <th>Release Date</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {releaseNotes.map((note, index) => {
            const releasedate = moment(note.releasedate).format("MMMM Do, YYYY");
            return (
              <tr key={note.releasenotesID}>
                <td>
                  {note.version}
                </td>
                <td>{releasedate}</td>
                <td>
                  <ReleaseNoteLink to={`${url}/${note.releasenotesID}`}>View Release Notes</ReleaseNoteLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
};

const TableWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 2em;

  table {
    display: flex;
    flex-direction: column;
    border-collapse: collapse;
    align-self: center;
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
  }
  thead,
  tbody {
    display: block;
  }

  tr {
    display: flex;

    &:nth-child(odd) {
      background-color: #f6f6f6;
    }

    th {
      background-color: #007cbb;
      color: #ffffff;
      font-weight: 500;
    }

    td,
    th {
      flex: 1;
      padding: 8px;
      text-align: left;
      border: 1px solid #eee;

      // control column widths with :nth-child(n)
      &:nth-child(1) {
        flex: 2.5;
      }
    }
    td {
      &:nth-child(1) {
        color: #016790;
        font-weight: 500;
      }
      &:nth-child(n + 5) {
        background-color: #ffeb001f;
      }
    }
  }
`;

const ReleaseNoteLink = styled(Link)`
`;

ReleaseNotesList.propTypes = {
  releaseNotes: PropTypes.array.isRequired,
  currentReleaseNoteIndex: PropTypes.numbers.isRequired,
  selectReleaseHandler: PropTypes.func.isRequired
};
