import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useLocation } from "react-router";
import { KPIBox } from "./";
import { STATE_KEY as SUMMARY_BOXES_STATE_KEY } from "../";
import styled from "styled-components";

// Actions
import { getSummaryBoxNumbers } from "../actions";

export const SummaryBoxes = () => {
  const summaryBoxData = useSelector(state => Object.keys(state[SUMMARY_BOXES_STATE_KEY].data).map(key => state[SUMMARY_BOXES_STATE_KEY].data[key]));
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getSummaryBoxNumbers());
  }, []);

  const handleOnClick = index => {
    const path = summaryBoxData[index].summaryBoxLink;
    history.push(path);
  };

  return (
    <Nav role="navigation" aria-label="Summary Boxes">
      <List>
        {summaryBoxData.map((box, i) => {
          return <KPIBox key={i} isLoading={!box.summaryBoxIsLoaded} isActive={pathname == box.summaryBoxLink} handleOnClick={() => handleOnClick(i)} title={box.summaryBoxTitle} tooltip={box.summaryBoxTooltip} number={box.summaryBoxNumber} colorClass={box.summaryBoxColorClass} iconClass={box.summaryBoxIconClass} />;
        })}
      </List>
    </Nav>
  );
};

const Nav = styled.nav`
  width: 100%;
  margin-top: 1.05rem;

  /******SIZE:SMALL***/
  @media screen and (min-width: 540px) {
    width: 100%;
  }
  /*****END SIZE SMALL*******/

  /******SIZE LARGE******/
  @media screen and (min-width: 1366px) {
    width: 100%;
  }
  /******END SIZE LARGE******/
`;

const List = styled.ul`
  display: table;
  margin: 0 auto;
  width: auto;
  position: relative;
  text-align: center;
`;
