/**
 * Account Request Component
 */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OrienAvatarLogo from "../../assets/Orien_logo_4C_2ndLOGOS_ORIENAVATAR.svg";
import { LogoImageWrapper, ErrorMessageWrapper, FieldWrapper, FieldErrorMessageWrapper, Button, Spinner, FooterLinksWrapper, Title, P, Label, ErrorSpan, FieldGroupWrapper } from "../styled/nonAuthenticatedAccountStyles";

import OrienAvatarAPI, { GENERIC_OA_API_KEY } from "../../api";
import { useAnalytics } from "../../../hooks";

// Using Yup to provide the schema validation for the form.
const requestAccountSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string()
    .required("Required")
    .email("Enter a valid email address"),
  affiliation: Yup.string().required("Required")
});

export const RequestAccount = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [accountRequested, setAccountRequested] = useState(false);
  const { trackEvent } = useAnalytics();

  const handleSubmit = (values, actions) => {
    setHasError(false);
    setErrorMessage("");
    setAccountRequested(false);

    const analyticsName = `${values.name} | ${values.email} | ${values.affiliation}`;

    OrienAvatarAPI.request({
      method: "post",
      url: `/accountrequest`,
      data: {
        name: values.name,
        email: values.email,
        affiliation: values.affiliation
      },
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": GENERIC_OA_API_KEY
      }
    })
      .then(response => {
        trackEvent("Users", "Request account succeeded", analyticsName);

        actions.setSubmitting(false);

        setHasError(false);
        setAccountRequested(true);
      })
      .catch(error => {
        trackEvent("Users", "Request account failed", analyticsName);

        const errorJSON = JSON.parse(JSON.stringify(error));
        let response = "An error has occurred. Please try again.";
        if (errorJSON && errorJSON.response && errorJSON.response.data) {
          response = errorJSON.response.data;
        }

        actions.setSubmitting(false);
        setHasError(true);
        setErrorMessage(response);
        setAccountRequested(false);
      });
  };

  return (
    <>
      <LogoImageWrapper role="heading" aria-level="1">
        <img src={OrienAvatarLogo} alt="ORIEN Avatar Logo" />
      </LogoImageWrapper>
      {hasError && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}

      <Title center>Request An Account</Title>
      {!accountRequested ? (
        <P center>
          Please complete the form below to request a new
          <br />
          Orien Avatar account.
        </P>
      ) : (
        <P center>Your account request has been successfully received.</P>
      )}

      {!accountRequested && (
        <Formik
          initialValues={{
            name: "",
            email: "",
            affiliation: ""
          }}
          validationSchema={requestAccountSchema}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FieldGroupWrapper>
                <Label htmlFor="name">
                  <ErrorSpan>*</ErrorSpan> Your Name:
                </Label>
                <FieldWrapper>
                  <Field type="text" name="name" id="name" autoComplete="name" onBlur={null} autoFocus />
                  <FieldErrorMessageWrapper name="name" component="div" className="error" />
                </FieldWrapper>
              </FieldGroupWrapper>

              <FieldGroupWrapper>
                <Label htmlFor="email">
                  <ErrorSpan>*</ErrorSpan> Email:
                </Label>
                <FieldWrapper>
                  <Field type="text" name="email" id="email" autoComplete="email" onBlur={null} />
                  <FieldErrorMessageWrapper name="email" component="div" className="error" />
                </FieldWrapper>
              </FieldGroupWrapper>

              <FieldGroupWrapper>
                <Label htmlFor="affiliation">
                  <ErrorSpan>*</ErrorSpan> Affiliation:
                </Label>

                <FieldWrapper>
                  <Field type="text" name="affiliation" id="affiliation" autoComplete="organization" onBlur={null} />
                  <FieldErrorMessageWrapper name="affiliation" component="div" className="error" />
                </FieldWrapper>
              </FieldGroupWrapper>

              <FieldWrapper>
                <Button type="submit" disabled={isSubmitting}>
                  <span>Request Account</span>
                  {isSubmitting && <Spinner />}
                </Button>
              </FieldWrapper>
            </Form>
          )}
        </Formik>
      )}
      <FooterLinksWrapper>
        <Link to="/account/login">Login</Link>
        {/*
         *<Link to="/faq">FAQ</Link>
         */}
      </FooterLinksWrapper>
    </>
  );
};
