import styled, { css } from "styled-components";
import { ErrorMessage } from "formik";

export const LogoImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  img {
    height: 220px;
  }
`;

export const ErrorMessageWrapper = styled.div`
  border: 1px solid #f5d0d6;
  background-color: #ffe7eb;
  padding: 1.4em;
  color: #000;
  border-radius: 4px;
  margin: 1.5em 0;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.2em 0;
  font-size: 1.2em;
  justify-content: center;
  align-items: center;
  color: #222;

  input[type="text"],
  input[type="password"] {
    width: 250px;
    padding: 4px 8px;

    & + div.error {
      margin-top: 0.4em;
      font-size: 0.8em;
      color: red;
    }
  }
`;

export const FieldErrorMessageWrapper = styled(ErrorMessage)`
  color: #ff0000;
`;

export const Button = styled.button`
  background-color: #007cbb;
  color: #ffffff;
  border: 1px solid #efefef;
  padding: 6px 8px;
  border-radius: 4px;
  transition: all 250ms ease;
  font-size: 1em;
  width: 250px;

  &:hover {
    background-color: #0097e4;
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
  }
`;

export const Spinner = styled.span`
  position: relative;
  margin: 0 1em;

  &::before {
    content: "";
    animation: 1000ms linear infinite spinner-rotate;
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 50%;
    border: dashed 3px transparent;
    border-bottom-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes spinner-rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2.5em;

  a + a::before {
    content: "";
    margin-left: 2em;
  }

  a {
    color: #0054b9;
  }
`;

export const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 1.5em;
  font-size: 1.6em;
  color: #007cbb;
  ${({ center }) =>
    center
      ? css`
          text-align: center;
        `
      : css`text-align: left`};
`;

export const P = styled.p`
  ${({ center }) =>
    center
      ? css`
          text-align: center;
        `
      : css`text-align: left`};
`;

export const Label = styled.label`
  flex: 1;
  max-width: 150px;
  font-weight: bold;
  margin-right: 2rem;
`;

export const ErrorSpan = styled.span`
  color: #ff0000;
`;

export const FieldGroupWrapper = styled.div`
  display: flex;
  align-items: center;
`;
