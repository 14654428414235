import OrienAvatarAPI from "../api";
import * as actionTypes from "./actionTypes";

export function getSummaryBoxNumbers() {
  return (dispatch, state) => {
    dispatch(setIsLoading(true));
    dispatch(setIsLoaded(false));

    const userType = state().user.userType;
    const company = state().user.company;
    const apiKey = state().user.apiKey;

    OrienAvatarAPI.request({
      method: "get",
      url: `https://${__GENERICAPI__}/orienavatar/${userType}/${company}/kpi`,
      headers: {
        Authorization: apiKey
      }
    })
      .then(response => {
        const data = response.data || {};
        //console.log("KPI response.data", data);
        const { molecularcounts: molecularCounts, clinicalcounts: clinicalCounts, avatarcounts: siteAvatarCounts, networkavatarcounts:  networkAvatarCounts } = data;

        molecularCounts && dispatch(setSummaryBoxNumber("molecular", molecularCounts));
        clinicalCounts && dispatch(setSummaryBoxNumber("clinical", clinicalCounts));
        siteAvatarCounts && dispatch(setSummaryBoxNumber("avatar", siteAvatarCounts));

        dispatch(setCounts({
          molecularCounts,
          clinicalCounts,
          siteAvatarCounts,
          networkAvatarCounts
        }));

      })
      .catch(error => {
        console.error("Failed to fetch KPIs", error);
        dispatch(setSummaryBoxNumber("molecular", ""));
        dispatch(setSummaryBoxNumber("clinical", ""));
        dispatch(setSummaryBoxNumber("avatar", ""));
      })
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setIsLoaded(true));
      });
  };
}

export const setIsLoading = isLoading => {
  return {
    type: actionTypes.SET_IS_LOADING,
    value: isLoading
  };
};

export const setIsLoaded = isLoaded => {
  return {
    type: actionTypes.SET_IS_LOADED,
    value: isLoaded
  };
};

export function setSummaryBoxNumber(key, number) {
  return {
    type: actionTypes.SET_SUMMARY_BOX_NUMBER,
    value: { key, number }
  };
}

export function setSummaryBoxNumbers(KPINumbers) {
  return {
    type: actionTypes.SET_SUMMARY_BOX_NUMBERS,
    value: KPINumbers
  };
}

export function selectSummaryBox(summaryBoxID) {
  return {
    type: actionTypes.SUMMARY_BOX_SELECT,
    value: summaryBoxID
  };
}

export function deselectSummaryBox() {
  return {
    types: actionTypes.SUMMARY_BOX_DESELECT,
    value: -1
  };
}

export const setCounts = counts => {
  return {
    type: actionTypes.SET_COUNTS,
    value: counts 
  };
}
