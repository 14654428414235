import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { STATE_KEY as USER_STATE_KEY, ChangePassword } from "../../User";
import styled from "styled-components";
import axios from "axios";

export const Account = () => {
  const dispatch = useDispatch();
  const userSelector = useSelector(state => state[USER_STATE_KEY]);

  return (
    <Wrapper>
      <SectionWrapper>
        {userSelector.companyPhotoURL && (
          <SectionCompanyPhotoWrapper>
            <img src={userSelector.companyPhotoURL} alt={`${userSelector.company} Logo`} />
          </SectionCompanyPhotoWrapper>
        )}
        <SectionTitle>Profile</SectionTitle>
        {userSelector.fullName && (
          <SectionFieldWrapper>
            <SectionLabel>Full Name:</SectionLabel>
            <SectionField>{userSelector.fullName}</SectionField>
          </SectionFieldWrapper>
        )}
        {userSelector.userName && (
          <SectionFieldWrapper>
            <SectionLabel>Username:</SectionLabel>
            <SectionField>{userSelector.userName}</SectionField>
          </SectionFieldWrapper>
        )}
        {userSelector.email && (
          <SectionFieldWrapper>
            <SectionLabel>Email:</SectionLabel>
            <SectionField>{userSelector.email}</SectionField>
          </SectionFieldWrapper>
        )}
        {userSelector.company && (
          <SectionFieldWrapper>
            <SectionLabel>Affiliation:</SectionLabel>
            <SectionField>{userSelector.company}</SectionField>
          </SectionFieldWrapper>
        )}
      </SectionWrapper>

      <ChangePassword />

      {/*
       *<SectionWrapper>
       *  <SectionTitle>Security</SectionTitle>
       *    <SectionFieldWrapper>
       *      <SectionLabel>Password:</SectionLabel>
       *        <SectionField>
       *          <p>*****************</p>
       *            <SectionButtonWrapper>
       *              <Link to="/account/changepassword">
       *                <SectionButton type="button">Change Password...</SectionButton>
       *  </Link>
       *    <SectionButtonInfo>Manually change your password</SectionButtonInfo>
       *  </SectionButtonWrapper>
       *    {
       *      //<SectionButtonWrapper>
       *      //<Link to="/account/resetpassword">
       *      //<SectionButton type="button">Reset Password</SectionButton>
       *      //</Link>
       *      //<SectionButtonInfo>Will generate and email you a new random password</SectionButtonInfo>
       *      //</SectionButtonWrapper>
       *    }
       *        </SectionField>
       *          </SectionFieldWrapper>
       *            </SectionWrapper>
       */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionWrapper = styled.div`
  border: 1px solid #ededed;
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 4px;
  width: 100%;
  max-width: 650px;
`;

const SectionCompanyPhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionTitle = styled.h2`
  color: #007cbb;
  text-align: left;
  font-weight: 500;
  margin-bottom: 2rem;
`;

const SectionFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.7rem 0;
`;

const SectionLabel = styled.div`
  flex: 1;
  max-width: 150px;
  font-weight: 500;
  margin-right: 2rem;
`;

const SectionField = styled.div``;

const SectionButtonWrapper = styled.div`
  & + & {
    margin-top: 2rem;
  }
`;

const SectionButton = styled.button`
  background-color: #007cbb;
  color: #ffffff;
  border: 1px solid #efefef;
  padding: 6px 8px;
  border-radius: 4px;

  &:hover {
    background-color: #0097e4;
  }
`;

const SectionButtonInfo = styled.p`
  margin-top: 0.5rem;
  color: #585858;
  font-size: 1.3rem;
`;
